import React, { useCallback } from 'react';
import { IHistorySession } from '../IDashboard';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { TableHeaders, UserRoleEnums } from '../../../redux/store/IStore';
import Tooltip from '@mui/material/Tooltip';
import { Download, Merge, RestartAlt } from '@mui/icons-material';
import { SessionState } from '../../DashboardHeader/ISearch';
import { enqueueSnackbar } from 'notistack';
import { setIsDashboardReady, setTableRows, updateDashboardRefresh } from '../../../redux/features/app/app';
import { deleteSession, getEditorContentV30, getSessionTranscripts, mergeSessions } from '../../../api/SessionsService';
import { AxiosResponse } from 'axios';
import { AccessMode } from '../../../types';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { IV3RecievedTranscript } from '../../Libraries/ILibraries';
import { paragraphTokens } from '../../../shared/paragrapher';
import { convertFromVersion3ToVersion2File } from '../../../api/dataConverters';
import { transformNotSavedSessionTranscriptsToLiveWordData } from '../../../shared/DataConverters';
import useDownload from '../../../hooks/useDownload';
import { cloneDeep } from 'lodash';


interface ITableRowProps {
    row: IHistorySession;
}
const TableRowIsHeader = ({
    row
}: ITableRowProps) => {

    const {
        handleDownloadDocxFromState
    } = useDownload();

    const user = useAppSelector(store => store.app.user);
    const tableRows = useAppSelector(store => store.app.tableRows);
    const isDashboardLoading = useAppSelector(store => store.app.isDashboardLoading);
    const tableHeaders = useAppSelector(store => store.app.tableHeaders);

    const dispatch = useAppDispatch();
    const updateData = (newData: IHistorySession[]) => dispatch(setTableRows(newData))

    const mergePrefixContent = useCallback(async () => {
        const prefix = row.name.split("/")[0]
        const mergeSessionIds: number[] = []
        const mergeSessionNames: string[] = []
        const prefixedSessions = tableRows.filter(session => session.name.startsWith(prefix) && !session.isHeader && session.status === SessionState.FINISHED)
        let name = ""
        let validID: number | null = null
        for (let sessionIndex = 0; sessionIndex < prefixedSessions.length; sessionIndex++) {
            const session = prefixedSessions[sessionIndex]
            if (sessionIndex === 0) {
                validID = session.id
                name = session.name
                if (session.isLocked) {
                    enqueueSnackbar("Seja se združuje ali ureja.", { variant: 'info' })
                    break;
                }
            }

            const sessionHasLabel = session.labels.filter(label => label.label.code === "Končano" && label.isEnabled).length === 1
            if (session.versionTags.length > 0 && sessionHasLabel) {
                mergeSessionIds.push(session.id)
                mergeSessionNames.push(session.name)
            } else {
                break;
            }
        }

        if (mergeSessionIds.length === 1) {
            enqueueSnackbar("Za združevanje potrebujete vsaj dve veljavni seji.", { variant: 'error' })
            return;
        }
        if (mergeSessionIds.length < 1) {
            enqueueSnackbar("Ena ali več sej ne zadoščajo pogojem za združevanje.", { variant: 'error' })
            return;
        }
        /*if (mergeSessionIds.length > 50) {
            setMergeSessionIds(mergeSessionIds.slice(0, 50))
            setMergeSessionNames(mergeSessionNames.slice(0,50))
            setIsMergeModalVisible(true);
            return;
        }*/

        enqueueSnackbar("Združevanje poteka. Seja bo kmalu na voljo.", { variant: 'info' })
        const copy = cloneDeep(tableRows)
        const mapped = copy.map(row => {
            row.merging = row.id === validID && row.isHeader
            return row;
        })

        updateData(mapped)

        try {
            await mergeSessions(mergeSessionIds, name);

        } catch (error) {
            console.log(error)
            const mappedError = error as any
            if (mappedError.response.status === 423) {
                enqueueSnackbar("Seja se združuje ali ureja.", { variant: 'info' })
            }
            enqueueSnackbar("Pri združevanju je prišlo do napake. Kontaktirajte tehnično podporo", { variant: 'error' })
        }

        const copyAfter = cloneDeep(tableRows)
        const mappedAfter = copyAfter.map(row => {
            row.merging = false
            return row;
        })

        updateData(mappedAfter)
        dispatch(updateDashboardRefresh(true))
        dispatch(setIsDashboardReady(false))
    }, [tableRows])

    const mergeDiscardedAndDiscardCurrentlyMerged = useCallback(async () => {
        const prefix = row.name.split("/")[0]
        console.log(prefix)
        const mergedSessions = tableRows.filter(session => session.name.startsWith(prefix) && session.sources.includes("MERGED") && !session.isHeader)
        if (mergedSessions.length !== 1) {
            enqueueSnackbar("Pri ponovnem združevanju je prišlo do napake.", { variant: 'error' })
            return;
        }

        const mergedSessionId = mergedSessions[0].id
        const mergedSessionName = mergedSessions[0].name

        const sessionsWithLabelFinished = tableRows.filter(session => session.labels.filter(label => label.label.code === "Končano" && label.isEnabled).length > 0).map(session => session.id);

        const sessionsIdsToMerge = tableRows.filter(session => session.name.startsWith(prefix) && !session.sources.includes("MERGED") && sessionsWithLabelFinished.includes(session.id)).map(session => session.id);

        console.log(sessionsIdsToMerge)
        enqueueSnackbar("Združevanje poteka. Seja bo kmalu na voljo.", { variant: 'info' })

        const copy = cloneDeep(tableRows)
        const mapped = copy.map(row => {
            row.merging = row.id === mergedSessionId && row.isHeader
            return row;
        })
        updateData(mapped)

        try {
            await mergeSessions(sessionsIdsToMerge, mergedSessionName);
            await deleteSession(mergedSessionId)
        } catch (error) {
            console.log(error);
            enqueueSnackbar("Pri združevanju je prišlo do napake. Kontaktirajte tehnično podporo", { variant: 'error' })
        }

        const copyAfter = cloneDeep(tableRows)
        const mappedAfter = copyAfter.map(row => {
            row.merging = false
            return row;
        })
        updateData(mappedAfter)
        dispatch(updateDashboardRefresh(true))
        dispatch(setIsDashboardReady(false))
    }, [tableRows])

    const mergeContentAndDownload = useCallback(async () => {
        const prefix = row.name.split("/")[0]
        const requests: Promise<AxiosResponse<any>>[] = []
        let firstSessionName = "transcript"
        let validID: number | null = null
        const prefixedSessions = tableRows.filter(session => session.name.startsWith(prefix) && !session.isHeader && session.status === SessionState.FINISHED)
        for (let sessionIndex = 0; sessionIndex < prefixedSessions.length; sessionIndex++) {
            const session = prefixedSessions[sessionIndex]
            if (session.recordedMs < 1) continue;
            if (sessionIndex === 0) {
                firstSessionName = session.name
                validID = session.id
            }

            session.versionTags.length > 0 ? requests.push(getEditorContentV30(session.id, null, AccessMode.READ_ONLY, null)) : requests.push(getSessionTranscripts(session.id))
        }

        if (requests.length < 1) return;

        enqueueSnackbar("Združevanje poteka. Seja bo kmalu na voljo.", { variant: 'info' })

        try {
            const responses = await Promise.all(requests)

            const editorStates: EditorState[] = []

            for (let responseIndex = 0; responseIndex < responses.length; responseIndex++) {
                const response = responses[responseIndex];
                const session = prefixedSessions[responseIndex]

                if (response.config.url && response.config.url.includes('text-segments')) {
                    const transcripts = response.data as IV3RecievedTranscript[];
                    if (transcripts.length === 0) continue;
                    const paragraphedTranscript = paragraphTokens(transcripts, undefined, 0);
                    const converted = convertFromVersion3ToVersion2File([paragraphedTranscript.newTranscripts]);
                    const { newEditorState } = await transformNotSavedSessionTranscriptsToLiveWordData(converted)
                    const raw = convertToRaw(newEditorState.getCurrentContent())

                    raw.blocks[0].data = {
                        ...raw.blocks[0].data,
                        blockPrefix: {
                            value: session.name
                        }
                    }

                    const keys = Object.keys(raw.entityMap)
                    const lastKey = keys[keys.length - 1]

                    const lastEndTime = raw.entityMap[lastKey].data.endTime

                    for (let entityIndex = 0; entityIndex < keys.length; entityIndex++) {
                        const entity = raw.entityMap[entityIndex]
                        if ((entity.data.startTime < 5 && responseIndex !== 0) || lastEndTime - entity.data.startTime < 5) {
                            raw.entityMap[entityIndex].data = {
                                ...raw.entityMap[entityIndex].data,
                                isOverlapping: true,
                            }
                        }
                    }

                    editorStates.push(EditorState.createWithContent(convertFromRaw(raw)))
                } else {
                    const response = responses[responseIndex];
                    const { rawContentState } = JSON.parse(response.data.content);

                    rawContentState.blocks[0].data = {
                        ...rawContentState.blocks[0].data,
                        blockPrefix: {
                            value: session.name
                        }
                    }

                    const keys = Object.keys(rawContentState.entityMap)
                    const lastKey = keys[keys.length - 1]

                    const lastEndTime = rawContentState.entityMap[lastKey].data.endTime

                    for (let entityIndex = 0; entityIndex < keys.length; entityIndex++) {
                        const entity = rawContentState.entityMap[entityIndex]
                        if ((entity.data.startTime < 5 && responseIndex !== 0) || lastEndTime - entity.data.startTime < 5) {
                            rawContentState.entityMap[entityIndex].data = {
                                ...rawContentState.entityMap[entityIndex].data,
                                isOverlapping: true,
                            }
                        }
                    }

                    editorStates.push(EditorState.createWithContent(convertFromRaw(rawContentState)))
                }
            }

            await handleDownloadDocxFromState(editorStates, firstSessionName)
        } catch (error) {
            console.log(error);
        }


        const copy = cloneDeep(tableRows)
        const mapped = copy.map(row => {
            row.merging = false
            return row;
        })
        updateData(mapped)
    }, [tableRows])

    return (

        <tr style={{backgroundColor: '#bcbcbc', height: 30}}>
            {tableHeaders.map(tableHeader => {
                if (tableHeader.key === TableHeaders.status) {
                    return (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', opacity: row.merging || isDashboardLoading ? 0.5 : 1, paddingLeft: 12, paddingTop: 3 }}>
                            {user && user.userRoles.includes(UserRoleEnums.GROUP_WRITE) && (
                                <button onClick={mergePrefixContent}><Tooltip style={{ cursor: 'pointer' }} placement="top" title="Združi seje"><Merge /></Tooltip></button>
                            )}
                            <button onClick={mergeContentAndDownload}><Tooltip style={{ cursor: 'pointer' }} placement="top" title="Prenesi seje"><Download /></Tooltip></button>
                            {user && user.userRoles.includes(UserRoleEnums.GROUP_WRITE) && (
                                <button onClick={mergeDiscardedAndDiscardCurrentlyMerged}>
                                    <Tooltip placement="top" title="Ponovno združi">
                                        <RestartAlt />
                                    </Tooltip>
                                </button>
                            )}
                        </div>
                    )
                } else if (tableHeader.key === TableHeaders.name) {
                    return <td key={`${row.id}-${tableHeader.key}`} className='table_row_cell table_row_text_cell' style={{ width: tableHeader.width, fontWeight: 700, color: "#3e95fe" }}><p>{row[tableHeader.key]}</p></td>
                } else {
                    return <td></td>
                }
            })}
        </tr>
    )
}

export default TableRowIsHeader;