import React, { useCallback, useEffect, useRef } from 'react'
import { useState } from 'react'
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './tablev3.css'
import '../Home/classess.css'
import { IHistorySession } from './IDashboard';
import { deleteSession, getEditorContentV30, getSessionsWithShares, getSessionTranscripts, mergeSessions } from '../../api/SessionsService';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import NewTable from './NewTable';
import useDownload from '../../hooks/useDownload';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DateTime } from 'luxon';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { setHistoryDate, setIsDashboardLoading, setTableRows, updateDashboardRefresh } from '../../redux/features/app/app';
import { IAppliedFilter, SessionState } from '../DashboardHeader/ISearch';
import { paragraphTokens } from '../../shared/paragrapher';
import { convertFromVersion3ToVersion2File } from '../../api/dataConverters';
import { transformNotSavedSessionTranscriptsToLiveWordData } from '../../shared/DataConverters';
import { cloneDeep } from 'lodash';
import Modal from '../Home/Modal';
import { AxiosResponse } from 'axios';
import { AccessMode } from '../../types';
import { IV3RecievedTranscript } from '../Libraries/ILibraries';
import Table from './Table/Table';
import { SortDirection } from '../../redux/store/IStore';

const parseSloDate = (dateStr: string): Date | null => {
    const arr = dateStr.split('.');
    if (arr.length !== 3) return null;
    const numArr = arr.map((v) => Number(v));
    let date = new Date(numArr[2], numArr[1] - 1, numArr[0])
    date = new Date(date.getTime() + (-1 * date.getTimezoneOffset() * 60000))
    return date;
};
const transformFilters = (appliedFilters: IAppliedFilter[]): URLSearchParams => {
    const urlParams: URLSearchParams = new URLSearchParams()

    appliedFilters.forEach((filter: IAppliedFilter) => {
        switch (filter[0].field) {
            case 'createdAt':
                if (filter.length === 3) {//user passed specific day or open interval at one side
                    let date = parseSloDate(filter[2].label);
                    if (!date) throw new Error(`Invalid date filter: value ${filter[2].label}`)

                    if (filter[1].field === "to") {
                        date = new Date(date.getTime() + 60 * 60 * 24 * 1000);
                    }
                    const dateStr = date.toISOString();

                    switch (filter[1].field) {
                        case 'to':
                            urlParams.append('created-at', `;${dateStr}`)
                            break;
                        case 'from':
                            urlParams.append('created-at', `${dateStr};`)
                            break;
                        case 'is':
                            const dateEnd = new Date(date.getTime() + 60 * 60 * 24 * 1000).toISOString();
                            urlParams.append('created-at', `${dateStr};${dateEnd}`)
                            break;
                        default: throw new Error(`Unknown date filter operation field: ${filter[1].field}`)
                    }
                } else if (filter.length === 5) {//user passed closed interval on both sides
                    let dateOne = parseSloDate(filter[2].label)
                    let dateTwo = parseSloDate(filter[4].label)

                    if (!dateOne) throw new Error(`Invalid date filter: value ${filter[2].label}`)
                    if (!dateTwo) throw new Error(`Invalid date filter: value ${filter[4].label}`)

                    if (filter[1].field === "from") {
                        dateTwo = new Date(dateTwo.getTime() + 60 * 60 * 24 * 1000);
                    } else {
                        dateOne = new Date(dateOne.getTime() + 60 * 60 * 24 * 1000);
                    }
                    const startDate = filter[1].field === "from" ? dateOne.toISOString() : dateTwo.toISOString()
                    const endDate = filter[1].field === "to" ? dateOne.toISOString() : dateTwo.toISOString()

                    urlParams.append('created-at', `${startDate};${endDate}`)
                } else throw new Error('Date filters should be of length 3 or 5.')
                break;
            case 'labels':
                let labelFilterValue = filter[2].label
                if (filter[1].field === "contains") labelFilterValue = `%${labelFilterValue}%`
                else if (filter[1].field === "startsWith") labelFilterValue = `${labelFilterValue}%`
                urlParams.append('label', labelFilterValue)
                break;

            case 'name':
                let namelFilterValue = filter[2].label
                if (filter[1].field === "contains") namelFilterValue = `%${namelFilterValue}%`
                else if (filter[1].field === "startsWith") namelFilterValue = `${namelFilterValue}%`
                urlParams.append('name', namelFilterValue)
                break;

            case 'search':
                urlParams.append('name', `${filter[1].label}`)
                break;

            case 'user':
                // admin only
                let userFilterValue = filter[2].label
                if (filter[1].field === "contains") userFilterValue = `%${userFilterValue}%`
                else if (filter[1].field === "startsWith") userFilterValue = `${userFilterValue}%`
                urlParams.append('created-by-username', userFilterValue)
                break;
            case 'group':
                let groupFilterValue = filter[2].label
                if (filter[1].field === "contains") groupFilterValue = `%${groupFilterValue}%`
                else if (filter[1].field === "startsWith") userFilterValue = `${groupFilterValue}%`
                urlParams.append('created-by-group', groupFilterValue)
                break;
            case 'sessionSource':
                // admin only
                let sessionSourceFilterValue = filter[2].label
                if (filter[1].field === "contains") sessionSourceFilterValue = `%${sessionSourceFilterValue}%`
                else if (filter[1].field === "startsWith") sessionSourceFilterValue = `${sessionSourceFilterValue}%`
                urlParams.append('source', sessionSourceFilterValue)
                break;
            case 'sessionStatus':
                // admin only
                let sessionStatusFilterValue = filter[2].label
                let sessionStatusFilterField = filter[1].field

                if (sessionStatusFilterValue === SessionState.DELETED) {
                    if (sessionStatusFilterField === "isNot") {
                        urlParams.append('is-discarded', 'false')
                    } else if (sessionStatusFilterField === "is") {
                        urlParams.append('is-discarded', 'true')
                    } else {
                        throw new Error(`Unknown uperation for filter SessionState. operation -> ${sessionStatusFilterField}`)
                    }
                } else {
                    if (sessionStatusFilterField === "isNot") {
                        Object.keys(SessionState).forEach(sessionState => {
                            if (sessionState !== sessionStatusFilterValue && sessionState !== SessionState.DELETED) {
                                urlParams.append('status', sessionState)
                            }
                        })
                    } else if (sessionStatusFilterField === "is") {
                        urlParams.append('status', sessionStatusFilterValue)
                    } else {
                        throw new Error(`Unknown uperation for filter SessionState. operation -> ${sessionStatusFilterField}`)
                    }
                }
                break;
            default:
                break;
        }
    });
    return urlParams;
};


const V3Table = () => {
    //const [data, setData] = useState<IHistorySession[]>([]);
    const scrollContainerRef = useRef<HTMLDivElement | null>(null)
    const tableRows = useAppSelector(state => state.app.tableRows);
    const startDate = useAppSelector(state => state.app.historyDate);
    const refreshDashboard = useAppSelector(state => state.app.refreshDashboard);
    const isDashboardReady = useAppSelector(state => state.app.isDashboardReady);
    const appliedFilters = useAppSelector(state => state.app.dashboardFilters);
    const dispatch = useAppDispatch();
    const [isMergeModalVisible, setIsMergeModalVisible] = useState<boolean>(false);
    const [stateMergeSessionIds, setMergeSessionIds] = useState<number[]>([]);
    const [stateMergeSessionNames, setMergeSessionNames] = useState<string[]>([]);

    const closeModal = () => {
        setIsMergeModalVisible(false)
        setMergeSessionIds([])
        setMergeSessionNames([])
    }

    const onDateChange = (date: Date | null) => {
        if (!date) return;
        dispatch(setHistoryDate(date))
    }

    const { enqueueSnackbar } = useSnackbar();

    const updateData = (newData: IHistorySession[]) => dispatch(setTableRows(newData))

    const mergeAfterConfirm = async () => {
        setIsMergeModalVisible(false)
        enqueueSnackbar("Združevanje poteka. Seja bo kmalu na voljo.", { variant: 'info' })
        const mapped = tableRows.map(row => {
            row.merging = row.id === stateMergeSessionIds[0] && row.isHeader
            return row;
        })
        updateData(mapped)

        try {
            await mergeSessions(stateMergeSessionIds, stateMergeSessionNames[0]);
        } catch (error) {
            console.log(error)
            const mappedError = error as any
            if (mappedError.response.status === 423) {
                enqueueSnackbar("Seja se združuje ali ureja.", { variant: 'info' })
            }
            enqueueSnackbar("Pri združevanju je prišlo do napake. Kontaktirajte tehnično podporo", { variant: 'error' })
        }

        const mappedAfter = tableRows.map(row => {
            row.merging = row.id === stateMergeSessionIds[0] && row.isHeader
            return row;
        })
        updateData(mappedAfter)

        setMergeSessionIds([])
        setMergeSessionNames([])
    }


    const onChangeRaw = (event: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined) => {
        console.log(event)
    }


    const addDay = () => {
        const newDate = DateTime.fromISO(startDate.toISOString()).plus({ day: 1 }).toISO()
        onDateChange(new Date(newDate as string))
        dispatch(updateDashboardRefresh(true))
    }

    const subtractDay = () => {
        const newDate = DateTime.fromISO(startDate.toISOString()).minus({ day: 1 }).toISO()
        onDateChange(new Date(newDate as string))
        dispatch(updateDashboardRefresh(true))
    }

    const getInitialData = useCallback(async () => {
        if (!isDashboardReady && !refreshDashboard) return;
        
        dispatch(setIsDashboardLoading(true))
        
        const filters: URLSearchParams = transformFilters(appliedFilters);
        filters.delete("created-at")

        if (!startDate) throw new Error(`Invalid date filter`)
        const month = String(startDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(startDate.getDate()).padStart(2, '0');
        const year = startDate.getFullYear()

        const nameFilter = `%${year}-${month}-${day}%`
        //filters.append("created-at", `${startDate};${endDate}`)
        filters.append("name", nameFilter)
        const {
            sessions,
            last
        } = await getSessionsWithShares(
            undefined,
            2000,
            filters,
            { columnName: 'createdAt', type: SortDirection.desc }
        );

        const sessionNameSet = new Set(sessions.map(session => session.content.name.split("/")[0]))

        if (sessionNameSet.size < 1) {
            updateData([])
            //setHasMore(false);
            dispatch(setIsDashboardLoading(false))
            return
        }

        const prefixFilters: URLSearchParams = new URLSearchParams();
        sessionNameSet.forEach(name => prefixFilters.append('name', `${name}%`))

        const {
            sessions: sessionsPrefixed,
            last: lastPrefixed
        } = await getSessionsWithShares(
            undefined,
            2000,
            prefixFilters,
            { columnName: 'name', type: SortDirection.asc }
        );

        const mapped: IHistorySession[] = sessionsPrefixed.filter(session => !(session.content.isDiscarded && session.content.sources.includes("MERGED"))).map(session => {
            return {
                ...session.content,
                shares: session.shares,
                cursor: session.cursor,
                expanded: tableRows.filter(currentSession => currentSession.id === session.content.id && currentSession.expanded && !currentSession.isHeader).length === 1,
                tagged: false,
                openedVersion: false,
                openedLabels: false,
                summary: session.summary,
                recordings: session.recordings,
                isHeader: false,
                merging: false,
            }
        }).sort((a, b) => {
            // First, sort by name
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;

            // If names are the same, sort by isDiscarded
            return (a.isDiscarded === b.isDiscarded) ? 0 : (a.isDiscarded ? 1 : -1);
        })

        const mappedResults: IHistorySession[] = []

        let previousPrefix: string = ''
        mapped.forEach(session => {
            const prefix = session.name.split("/")[0];
            if (prefix !== previousPrefix) {
                mappedResults.push({
                    ...session,
                    expanded: false,
                    isHeader: true,
                    name: prefix,
                })
                previousPrefix = prefix;
            }

            mappedResults.push(session);
        })

        dispatch(setIsDashboardLoading(false))
        dispatch(updateDashboardRefresh(false))
        updateData(mappedResults)
    }, [tableRows, refreshDashboard, startDate, isDashboardReady])

    useEffect(() => {
        const interval = setInterval(async () => getInitialData(), 60*1000)

        return () => {
            clearInterval(interval)
        }
    }, [tableRows, startDate])

    useEffect(() => {
        getInitialData()
    }, [startDate, refreshDashboard])

    useEffect(() => {
        getInitialData()
    }, [])

    return (
        <div style={{backgroundColor: "#f7f7f7"}}>
            <div style={{zIndex: 1000}} id="date-picker-container">
                <div style={{ cursor: 'pointer' }} onClick={subtractDay}><ArrowLeft /></div>
                <DatePicker selected={startDate} className='date_input' value={`${startDate.getDate()}.${startDate.getMonth() + 1}.${startDate.getFullYear()}`} onChangeRaw={onChangeRaw} onChange={onDateChange} />
                <div style={{ cursor: 'pointer' }} onClick={addDay}><ArrowRight /></div>
            </div>
            <div style={{ width: '80vw', margin: '0 auto', overflowX: 'scroll' }}>
                <div id="table_scroll_container" ref={scrollContainerRef} style={{ height: '60vh', overflow: 'scroll' }}>
                    <DndProvider backend={HTML5Backend}>
                        {/*<NewTable updateData={() => {}} data={tableRows} />*/}
                        <Table />
                    </DndProvider>
                </div>
            </div>
        </div>
    )
}

export default V3Table;