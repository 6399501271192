import React from 'react';

import { IRecordingsArray, Task } from "../../Home/ISettings";
import { DateTime } from 'luxon';
import moment from 'moment';
import './recordings_table.css';

enum RecordingTableHeaders {
    Number = "#",
    Recording = "Posnetek",
    Duration = "Dolžina posnetka",
    CreatedAt = "Datum kreiranja",
    /*User = "Uporabnik",*/
    Model = "Model",
    Settings = "Nastavitve",
}

interface RecordingTableProps {
    recordings: IRecordingsArray | undefined;
}
const RecordingsTable = ({
    recordings
}: RecordingTableProps) => {
    return (
        <table className="recordings-table">
            <thead className='recordings-table-thead'>
                <tr>
                    {Object.values(RecordingTableHeaders).map(header => {
                        let className = 'left-aligned-recording-table-header'
                        if (
                            header === RecordingTableHeaders.Duration ||
                            header === RecordingTableHeaders.Settings
                        ) {
                            className = 'right-aligned-recording-table-header'
                        }

                        return <td className={`${className} recordings-table-header-cell`}>{header}</td>
                    })}
                </tr>
            </thead>
            <tbody className='recordings-table-tbody'>
                {recordings && recordings.length > 0 && recordings.map((recording, index) => {

                    const asrPipeIndex = recording.pipeline.findIndex(pipe => pipe.task === Task.asr)
                    const model = asrPipeIndex < 0 ? "Brez ASR" : recording.pipeline[asrPipeIndex].config.tag
                    const duration = moment.duration(recording.recordedMs);

                    const hours = Math.floor(duration.asHours());
                    const minutes = Math.floor(duration.minutes());
                    const seconds = Math.floor(duration.seconds());
                    const remainingMilliseconds = Math.floor(duration.milliseconds());

                    const formatedDuration = `${hours > 0 ? hours + ':' : ''}${minutes > 0 ? minutes + ':' : ''}${seconds}.${remainingMilliseconds}`


                    let configString = ""
                    let configDescription = ""

                    const asrIndex = recording.pipeline.findIndex(stage => stage.task === Task.asr)
                    const itnIndex = recording.pipeline.findIndex(stage => stage.task === Task.itn)
                    const pcIndex = recording.pipeline.findIndex(stage => stage.task === Task.pc)

                    if (asrIndex >= 0) {
                        const asrParameters = recording.pipeline[asrIndex].config.parameters

                        /*configString += asrParameters.enableSd ? "T" : "F"
                        configDescription += asrParameters.enableSd ? "Samodejna razpoznava govorcev: T\n" : "Samodejna razpoznava govorcev: F\n"*/

                        configString += asrParameters.enableInterims ? "T" : "F"
                        configDescription += asrParameters.enableInterims ? "Prikazuj delne transkripte: T\n" : "Prikazuj delne transkripte: F\n"

                        configString += asrParameters.enableUnks ? "T" : "F"
                        configDescription += asrParameters.enableUnks ? "Neznane besede: T\n" : "Unki: F\n"
                    } else {
                        /*configString += "F"
                        configDescription += "Samodejna razpoznava govorcev: F\n"*/

                        configString += "F"
                        configDescription += "Prikazuj delne transkripte: F\n"

                        configString += "F"
                        configDescription += "Neznane besede: F\n"
                    }

                    if (itnIndex >= 0) {
                        configString += "T"
                        configDescription += "Denormalizacija: T\n"
                    } else {
                        configString += "F"
                        configDescription += "Denormalizacija: F\n"
                    }

                    if (pcIndex >= 0) {
                        const pcParameters = recording.pipeline[pcIndex].config.parameters
                        configString += "T"
                        configDescription += "Samodejno postavljanje ločil: T\n"

                        configString += pcParameters.enableSplitToSentences ? "T" : "F"
                        configDescription += pcParameters.enableSplitToSentences ? "Razbijaj končne transkripte na povedi: T\n" : "Razbijaj končne transkripte na povedi: F\n"

                        /*configString += pcParameters.enableTc ? "T" : "F"
                        configDescription += pcParameters.enableTc ? "Samodejno postavljanje velikih začetnic: T\n" : "Samodejno postavljanje velikih začetnic: F\n"*/
                    } else {
                        configString += "F"
                        configDescription += "Samodejno postavljanje ločil: F\n"

                        configString += "F"
                        configDescription += "Razbijaj končne transkripte na povedi: F\n"
                    }

                    return (
                        <tr>
                            <td className='recordings-table-cell'>{index + 1}</td>
                            <td className='recordings-table-cell'>{recording.id}</td>
                            <td className='recordings-table-cell' style={{ textAlign: 'right' }}>{formatedDuration}</td>
                            <td className='recordings-table-cell'>{DateTime.fromISO(recording.createdAt, { zone: 'UTC' }).setZone('local').toFormat('dd.MM.yyyy HH:mm:ss')}</td>
                            <td className='recordings-table-cell'>{model}</td>
                            <td className="recordings-table-cell recording-info-tooltip">
                                <p style={{ textAlign: 'right' }}>{configString}</p>
                                <span className="tooltiptext">
                                    {configDescription.split("\n").map(descritpion => <p>{descritpion}</p>)}
                                </span>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

export default RecordingsTable;