import React, { SetStateAction, useCallback, useEffect, useState } from 'react';
import { ExtendIcon } from '../Icons/ExtendIcon';
import { ShrinkIcon } from '../Icons/ShrinkIcon';
import Timebar from '../AudioPlayer/Timebar';
import { AnimButtonStatesEnum, EditorModeEnums, IStore, UserRoleEnums } from '../../redux/store/IStore';
import '../../styles/css/footer.css';
import { EditorState } from 'draft-js';
import AnimatedButton, { Animations, ButtonIcons } from '../../shared/AnimatedButton';
import { AudioCommandsFooter } from './AudioCommandsFooter';
import { AnimatePresence } from 'framer-motion';
import {
  ICommandSub,
  CurrentCommandModeEnums,
  IModal,
  ModalVariantsEnums,
  ILastSaved,
} from '../Editor/IEditor';
import { useDimensions } from '../../hooks/useDimensions';
import useEditor from '../../hooks/useEditor';
import { SessionInfo } from '../Header/Header';
import { unlockSession } from '../../api/SessionsService';
import Collapse from '@mui/material/Collapse';
import LinearProgress from '@mui/material/LinearProgress';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { setHomeFlowState, updateDashboardRefresh } from '../../redux/features/app/app';

interface IProps {
  currentSelectedSub?: ICommandSub;
  duration: any;
  lastSaved: ILastSaved;
  onTimeUpdate: any;
  sessionId?: number | null;
  setModal: React.Dispatch<SetStateAction<IModal>>;
  editorState: EditorState;
  footerTr: { finalStr: string; interimStr: string };
  toggleRecording: () => void;
  handleLeaveEditor: (v: ModalVariantsEnums) => void;
  canDiscard: boolean;
  readOnly: boolean;
}

const Footer = ({
  footerTr,
  duration,
  onTimeUpdate,
  setModal,
  sessionId,
  currentSelectedSub,
  lastSaved,
  canDiscard,
  toggleRecording,
  handleLeaveEditor,
  readOnly
}: IProps) => {
  const dispatch = useAppDispatch();
  const [extended, setExtended] = useState<boolean>(true);
  const { fixSpellCommandMode, cancelFixCommandMode, commandModeSubs, commandModeMoveSubs, insCommandMode } =
    useEditor();
  const editorMode = useAppSelector(state => state.app.editorMode);
  const user = useAppSelector(state => state.app.user);
  const animBtnState = useAppSelector(state => state.app.animButtonStates);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [animation, setAnimation] = useState<Animations>();
  const { ref, dimensions } = useDimensions();
  const editorLock = useAppSelector(store => store.app.editorLock)

  useEffect(() => {
    if (
      fixSpellCommandMode.currentCommandMode !== CurrentCommandModeEnums.INIT ||
      insCommandMode.isOn === true
    ) {
      setExtended(true);
    }
  }, [fixSpellCommandMode.currentCommandMode, insCommandMode.isOn]);

  useEffect(() => {
    if (animBtnState === AnimButtonStatesEnum.WAITING_TO_START) {
      setAnimation(Animations.FIRST);
    } else if (animBtnState === AnimButtonStatesEnum.WAITING_TO_STOP) {
      setAnimation(Animations.SECOND);
    } else if (animBtnState === AnimButtonStatesEnum.RECORDING) {
      setAnimation(Animations.BREATHE);
    } else {
      setAnimation(undefined);
    }
  }, [animBtnState]);

  const onExtenderClick = () => {
    setExtended(!extended);
  };

  const onDiscardClick = async () => {
    if (loadingSave || !canDiscard) return;
    if (editorLock.sessionId) {
      try {
        await unlockSession(editorLock.sessionId, editorLock.sessionLockKey)
      } catch (error) {
        console.log('Session unlock failed')
      }
    }
    setModal({ show: true, variant: ModalVariantsEnums.DISCARD });
  };

  const onCloseClick = async () => {
    //setModal({ show: true, variant: ModalVariantsEnums.EXIT });
    console.log(editorLock.sessionLockKey)
    if (editorLock.sessionId && editorLock.sessionLockKey) {
      try {
        await unlockSession(editorLock.sessionId, editorLock.sessionLockKey)
      } catch (error) {
        console.log('Session unlock failed')
      }
    }

    dispatch(updateDashboardRefresh(true))
    dispatch(setHomeFlowState({ liveFlowInProgress: false }));
    handleLeaveEditor(ModalVariantsEnums.EXIT);
  }

  const isInCommandMode =
    fixSpellCommandMode.currentCommandMode !== CurrentCommandModeEnums.INIT || insCommandMode.isOn;
  const canRecordLive = user?.userRoles.includes(UserRoleEnums.PIPELINE_ONLINE_API);


  const recordingCallback = () => {
    if (canRecordLive) {
      toggleRecording();
    }
  }
  return (
    <>
      <div id="footer" className="footer_container" ref={ref}>
        <Collapse in={isInCommandMode ? true : extended} collapsedSize="7px">
          <div className="footer_collapse_content_wrapper">
            {!isInCommandMode && <Timebar duration={duration} onTimeUpdate={onTimeUpdate} />}
            {isInCommandMode && (
              <CurrentTranscriptInFixMode
                text={footerTr.finalStr.length !== 0 ? footerTr.finalStr : footerTr.interimStr}
              />
            )}

            <div
              className="footer_content_wrapper"
              style={isInCommandMode ? { backgroundColor: '#2B8DFF' } : {}}
            >
              <AnimatePresence>
                {isInCommandMode ? (
                  <AudioCommandsFooter
                    footerWidth={dimensions.width}
                    cancelFixCommandMode={cancelFixCommandMode}
                    commandModeSubs={commandModeSubs}
                    fixSpellCommandMode={fixSpellCommandMode}
                    currentSelectedSub={currentSelectedSub}
                    moveLeftOrRight={commandModeMoveSubs}
                  />
                ) : (
                  <>
                    <SessionInfo
                      currentTitle=""
                      lastSaved={lastSaved}
                      sessionId={sessionId}
                      footerWidth={dimensions?.width}
                      readOnly={readOnly}
                    />
                    <div className="recording_icon_wrapper_main">
                      {editorMode !== EditorModeEnums.TRANSCRIBING_UPLOAD_MODE &&
                        editorMode !== EditorModeEnums.TB_UPLOAD_MODE &&
                        canRecordLive && (
                          <AnimatedButton
                            icon={ButtonIcons.RECORD}
                            running={animBtnState === AnimButtonStatesEnum.RECORDING}
                            disabled={
                              animBtnState === AnimButtonStatesEnum.WAITING_TO_START ||
                              animBtnState === AnimButtonStatesEnum.WAITING_TO_STOP ||
                              !canRecordLive
                            }
                            animation={
                              animBtnState === AnimButtonStatesEnum.RECORDING ? Animations.BREATHE : animation
                            }
                            onClick={recordingCallback}
                          />
                        )}
                    </div>

                    {(editorMode === EditorModeEnums.EDIT_MODE ||
                      editorMode === EditorModeEnums.PLAYING_MODE) &&
                      !animation && (
                        <div className="footer_commands_wrapper">
                          {/*<button
                            className="button_secondary mr_s"
                            style={{
                              cursor: loadingSave || !canDiscard ? 'not-allowed' : 'pointer',
                              pointerEvents: canDiscard ? 'all' : 'none',
                              opacity: canDiscard ? 1 : 0,
                            }}
                            disabled={!canDiscard}
                            onClick={onDiscardClick}
                          >
                            ZAVRZI
                          </button>*/}
                          <button
                            className="button_primary"
                            style={{
                              position: 'relative',
                              cursor: loadingSave ? 'not-allowed' : 'pointer',
                            }}
                            onClick={onCloseClick}
                          >
                            {loadingSave && (
                              <div
                                style={{
                                  position: 'absolute',
                                  top: '0',
                                  left: '0',
                                  width: '100%',
                                }}
                              >
                                <LinearProgress color="secondary" />
                              </div>
                            )}
                            <span style={{ opacity: loadingSave ? '0.5' : '1' }}>KONČAJ</span>
                          </button>
                        </div>
                      )}
                  </>
                )}
              </AnimatePresence>
            </div>

            <div></div>
          </div>
        </Collapse>
        {!isInCommandMode && (
          <button
            onClick={onExtenderClick}
            style={{
              width: 30,
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              top: extended ? 4 : -24,
              cursor: 'pointer',
            }}
          >
            {extended ? (
              <ExtendIcon
                style={{
                  width: 30,
                }}
              />
            ) : (
              <ShrinkIcon
                style={{
                  width: 30,
                }}
              />
            )}
          </button>
        )}
      </div>
    </>
  );
};

export default Footer;

function CurrentTranscriptInFixMode({ text }: any) {
  const [showCommandsText, setShowCommandsText] = useState(true);
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: 35,
        transform: `translateY(${showCommandsText ? -100 : 0}%)`,
        transition: 'transform 250ms ease-in-out',
        backgroundColor: '#CCCCCC',
        padding: '0 54px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        fontFamily: 'Roboto',
        fontSize: '20px',
        color: '#2B8DFF',
        overflow: 'hidden',
        overflowY: 'auto',
      }}
    >
      <button
        onClick={() => setShowCommandsText(!showCommandsText)}
        style={{
          width: 30,
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          top: -24,
          color: 'gray',
        }}
      >
        {showCommandsText ? (
          <ExtendIcon
            fill="currentColor"
            style={{
              width: 30,
            }}
          />
        ) : (
          <ShrinkIcon
            fill="currentColor"
            style={{
              width: 30,
            }}
          />
        )}
      </button>
      <p
        style={{
          textAlign: 'left',
          fontFamily: 'Roboto',
          fontSize: '20px',
          color: '#2B8DFF',
          margin: 0,
          padding: 0,
        }}
      >
        {text}
      </p>
    </div>
  );
}
