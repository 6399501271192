import React from 'react';
import TableHeaderRow from './TableHeaderRow';

const TableHeader = () => {
    return (
        <thead style={{ zIndex: 900, position: 'sticky', top: 0, backgroundColor: '#F7F7F7', boxShadow: "0px 3px 0px #707070" }}>
            <TableHeaderRow />
        </thead>
    )
}

export default TableHeader;