import React from 'react';
import TableHeader from './TableHeader';
import TableContent from './TableContent';

const Table = () => {
    return (
        <table id="sessions_table" style={{ overflowX: 'scroll', tableLayout: 'fixed' }}>
            <TableHeader />
            <TableContent />
        </table>
    )
}

export default Table;