import React, { useEffect, useState } from "react"
import './progress_bar.css'
import AnimateHeight from "react-animate-height";
import LinearProgress from "@mui/material/LinearProgress";
import { useAppSelector } from "../../redux/store";

const ProgressBar = () => {
    const [expand, setExpand] = useState<boolean>(false)
    const isDashboardLoading = useAppSelector(state => state.app.isDashboardLoading);
    
    useEffect(() => {
        console.log(isDashboardLoading)
        setExpand(isDashboardLoading)
    }, [isDashboardLoading])

    return (
        <LinearProgress className={`${expand ? "animation" : "animation_hidden"}`}/>
    ) 
}

export default ProgressBar;