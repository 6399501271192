import React from 'react';
import TableRow from './TableRow';
import { useAppSelector } from '../../../redux/store';
import TableRowIsHeader from './TableRowIsHeader';

const TableContent = () => {
    const tableRows = useAppSelector(store => store.app.tableRows);

    return (
        <tbody>
            {tableRows.map((row, index) => {
                return row.isHeader ? <TableRowIsHeader key={index.toString()} row={row} /> : <TableRow key={index.toString()} row={row} />
            })}
        </tbody>
    )
}

export default TableContent;